<template>
  <div class="unOuter mainOuter">
    <div class="container_un animated fadeInLeft ani_delay05">
      <div class="subWhite">
        <div class="sub_bg_bot"></div>
        <div class="subTitBox">
          <p id="t_page_title" class="subTitle">{{$t('menu.inquiry')}}</p>
        </div>
        <div class="RightBox RightBox-board">
          <div class="boardTit">
            <form novalidate="" id="searchForm" class="ng-untouched ng-pristine ng-valid">
              <div class="searchWrap">
                <input type="hidden" name="board_id" value="QNA">
                <input type="hidden" name="search" value="mem_id">
                <input type="hidden" name="searchword" value="take1106">
              </div>
            </form>
          </div>

          <table id="notice_list" aria-label="notice list">
            <colgroup>
              <col style="width:10%">
              <col style="width:25%">
              <col style="width:15%">
            </colgroup>
            <thead>
              <tr class="card_bg5">
                <th class="tcenter">{{$t('inquiry.no')}}</th>
                <th class="tcenter">{{$t('inquiry.title')}}</th>
                <th class="tcenter">{{$t('inquiry.status')}}</th>
                <th class="tcenter">{{$t('inquiry.answer')}}</th>
              </tr>
            </thead>
            <tbody class="ng-star-inserted" v-if="list.length > 0">
              <tr class="ng-star-inserted" v-for="(item,index) in list" :key="index">
                <td class="tcenter maven qna_num">{{ item.no }}</td>
                <td class="qna_id">
                  <router-link :to="`/inquiry/detail/${item.code}`">{{ item.title }}</router-link>
                </td>
                <td class="qna_id">
                  <span class="text-primary ng-star-inserted">{{ checkStatus(item.status) }}</span>
                </td>
                <td class="letter_zero maven qna_date">
                  <span class="ng-star-inserted">{{ item.r_status }}</span>
                </td>
              </tr>
              </tbody>
            <tbody class="ng-star-inserted" v-if="list.length === 0">
            <tr class="ng-star-inserted">
              <td class="tcenter maven qna_num" colspan="4">{{$t('inquiry.noList')}}</td>
            </tr>
            </tbody>
          </table>
          <div class="tright mgt30">
                        <span id="display_write_btn" class="bBlack card_bg5">
                            <router-link to="/inquiry/edit">{{$t('inquiry.write')}}</router-link>
                        </span>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
const CryptoJS = require("crypto-js");
export default {
  data() {
    return {
      list: []
    }
  },
  mounted() {
    this.getInquiryList();
  },
  methods: {
    getInquiryList() {
      this.$axios.post('/member/board/GetInquiryList', {}).then(
          res => {
            if (res.status === 200) {
              if (res.data.code === 200) {
                const e_body = res.data.body;
                const bytes = CryptoJS.AES.decrypt(e_body, process.env.VUE_APP_SECRET_TOKEN);
                const d_res = bytes.toString(CryptoJS.enc.Utf8);
                const body = JSON.parse(d_res)
                this.list = body.list;
              } else if (res.data.code === 9999) {
                this.$store.dispatch('SETLOGOUT').then(
                    () => {
                      window.location.href = "/sign/login";
                    }
                )
              }
            }
          }
      )
    },
    checkStatus(status) {
      if (status === 'H') {
        return this.$i18n.t('inquiry.answerReady')
      } else if (status === 'Y') {
        return this.$i18n.t('inquiry.answerComplete')
      }
    },
    CheckRStatus(status) {
      if (status === 'N') {
        return this.$i18n.t('inquiry.statusN')
      } else if (status === 'Y') {
        return this.$i18n.t('inquiry.statusY')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.text-primary {
  color: #d99709 !important;
}
</style>
