<template>
  <div class="unOuter mainOuter">
    <div class="container_un animated fadeInLeft ani_delay05">
      <div class="subWhite">
        <div class="sub_bg_bot"></div>
        <div class="subTitBox">
          <p id="t_page_title" class="subTitle">{{ $t('menu.inquiry') }}</p>
        </div>
        <div class="RightBox RightBox-board">
          <div class="text-white">
            <h5>{{ info.title }}</h5>
            <div class="content">
              {{ info.memo }}
            </div>

            <hr class="py-3">
            <div>
              <button class='btn btn-sm bg-warning text-white' v-if="info.image!=''"
                      @click="GotoPopup(info.image)">{{ $t('inquiry.checkFile') }}
              </button>
            </div>
            <hr class="pb-3" v-if="info.image!=''">
            <h5>{{ $t('inquiry.answer') }}</h5>
            <div class="content">
              <span v-if="info.r_status == 'N'">{{ $t('inquiry.noResponse') }}</span>
              {{ info.r_memo }}
            </div>
          </div>

          <div class="tright mgt30">
                        <span id="display_write_btn" class="bBlack">
                            <router-link to="/inquiry">{{ $t('common.list') }}</router-link>
                        </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

const CryptoJS = require("crypto-js");
export default {
  data() {
    return {
      info: {
        title: '',
        memo: '',
        status: '',
        r_status: '',
        r_memo: '',
      },
      code: '',
    }
  },
  mounted() {
    this.code = this.$route.params.idx;
    this.GetInquiryDetail();
  },
  methods: {
    GetInquiryDetail() {
      const code = this.code;

      const body = {code};

      const req = CryptoJS.AES.encrypt(JSON.stringify(body),
          process.env.VUE_APP_SECRET_TOKEN).toString();

      this.$axios.post('/member/board/GetInquiryDetail', {req}).then(
          res => {
            if (res.status === 200) {
              if (res.data.code === 200) {
                const e_body = res.data.body;
                const bytes = CryptoJS.AES.decrypt(e_body, process.env.VUE_APP_SECRET_TOKEN);
                const d_res = bytes.toString(CryptoJS.enc.Utf8);
                const body = JSON.parse(d_res)
                this.info = body.info;
              } else if (res.data.code === 9999) {
                this.$store.dispatch('SETLOGOUT').then(
                    () => {
                      window.location.href = "/sign/login";
                    }
                )
              }
            }
          }
      )
    },
    GotoPopup(url) {
      window.open(url)
    }
  },

}
</script>

<style lang="scss" scoped>
.content {
  line-height: 1.8;
}
</style>
