<template>
    <div class="unOuter mainOuter">
        <div class="container_un animated fadeInLeft ani_delay05">
            <div class="subWhite">
                <div class="sub_bg_bot"></div>
                <div class="subTitBox">
                    <p id="t_page_title" class="subTitle">{{$t('inquiry.editTitle')}}</p>
                </div>
                <div class="RightBox RightBox-board">
                    <div class="gray_list">
                        <div class="ng-untouched ng-pristine ng-valid">
                            <table class="list_write" aria-label="">
                                <colgroup>
                                    <col style="width:15%">
                                    <col style="width:85%">
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>
                                            <span id="disp_title_1" style="color: #fff;">{{$t('inquiry.upload')}}</span>
                                        </th>
                                        <td style="text-align: left;">
                                            <img :src="image" class="prof-img ng-star-inserted" width="30" :alt="$t('inquiry.selectFile')">
                                            <div class="form-group">
                                                <div>
                                                    <input type="file" id="file" class="hidden d-none" style="width: 78px;"  @change="UploadIMG">
                                                    <label for="file" style="background-color: #fff; padding: 4px 12px; color: #000; border-radius: 2px; margin-top: 12px; font-size: 12px;">{{$t('inquiry.selectFile')}}</label>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr></tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th>
                                            <span id="disp_title_2">{{$t('common.title')}}</span>
                                        </th>
                                        <td class="tleft">
                                            <input type="text" name="title" class="form-control input-md ng-untouched ng-pristine ng-valid" style="background-color: #fff; color: #000;" ng-reflect-name="title" v-model="title" maxlength="100">
                                        </td>
                                    </tr>
                                    <tr>
                                        <th class="mtxt_none">
                                            <span id="disp_title_3">{{$t('common.content')}}</span>
                                        </th>
                                        <td class="tleft">
                                            <textarea name="i_content" class="form-control ng-untouched ng-pristine ng-valid" style="width: 100%; min-width: 200px; height: 200px; display: block; color: #000;" ng-reflect-name="i_content" v-model="memo"></textarea>
                                        </td>
                                    </tr>
                                    <input type="hidden" name="i_notice" value="N">
                                    <input type="hidden" name="i_private" value="Y">
                                </tbody>
                            </table>
                            <div class="bWrap">
                                <button type="submit" id="t_btn_ok" class="bOk me-2" @click="InquirySubmit()">{{$t('common.submit')}}</button>
                                <router-link to="/inquiry" id="t_btn_cancel" class="bCa">{{$t('common.cancel')}}</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
const CryptoJS = require("crypto-js");
import AWS from 'aws-sdk';

export default {
    data(){
        return{
            region : process.env.VUE_APP_AWSBUCKETREGION,
			IdentityPoolId: process.env.VUE_APP_AWSPOOLID,
			AWSURL:process.env.VUE_APP_AWSURL,
			Bucket:process.env.VUE_APP_AWSBUCKETNAME,
            open_item: -1,
            list: [],
            title:'',
            memo :'',
            image : "/camera.png",
            default_image : '/camera.png'
        }
    },
    methods : {
        UploadIMG(e){
            if(e.target.files.length ==0){
                this.image = this.default_image;
            }else{
                const file = e.target.files[0];
                const date = new Date();
                const timestamp = date.getTime();
                const fileName = timestamp+file.name;
                AWS.config.update({region: this.region,credentials: new AWS.CognitoIdentityCredentials({IdentityPoolId: this.IdentityPoolId})});
                var s3 = new AWS.S3({apiVersion: "2006-03-01",params: { Bucket: this.Bucket}});
                s3.upload({Key: fileName, Body : file, ACL:'public-read','ContentType':`${file.type}`}, (err)=>{
                    if(err == null){
                        
                        const path = this.AWSURL + fileName;
                        this.image = path;
                    }else{
                        
                    }
                })
            }
            
        },
        InquirySubmit(){
            const title = this.title;
            const memo = this.memo;
            let image = this.image;
            
            if(image =='/camera.png'){
                image = '';
            }
            

            const body = {title,memo,image};
            
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            
            this.$axios.post('/member/board/INQUIRY_INSERT', {req}).then(
                res => {
                    if(res.status ==200){
                        if(res.data.code=='200'){
                            this.$alert(this.$i18n.t('inquiry.submitComplete')).then(
                                ()=>{
                                    this.$router.push({path : '/inquiry'})
                                }
                            )
                        }else if(res.data.code=='9999'){
                            this.$store.dispatch('SETLOGOUT').then(
                                ()=>{
                                    window.location.href="/sign/login";
                                }
                            )
                        }
                    }
                }
            )
        }
    }
}
</script>